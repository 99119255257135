@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes primaryRipple {
  0% {
    box-shadow: 0 0 0 0 #5e8b7e;
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(10deg);
  }
  15% {
    transform: rotate(-10deg);
  }
  20% {
    transform: rotate(10deg);
  }
  25% {
    transform: rotate(-10deg);
  }
  30% {
    transform: rotate(10deg);
  }
  35% {
    transform: rotate(-10deg);
  }
  40% {
    transform: rotate(10deg);
  }
  45% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(10deg);
  }
  55% {
    transform: rotate(-10deg);
  }
  60% {
    transform: rotate(10deg);
  }
  65% {
    transform: rotate(-10deg);
  }
  70% {
    transform: rotate(10deg);
  }
  75% {
    transform: rotate(-10deg);
  }
  80% {
    transform: rotate(10deg);
  }
  85% {
    transform: rotate(-10deg);
  }
  90% {
    transform: rotate(10deg);
  }
  100% {
    box-shadow: 0 0 0 1rem transparent;
    transform: rotate(0deg);
  }
}

@keyframes dashPop {
  0% {
    transform: translateY(5rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
