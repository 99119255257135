@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;800&display=swap');
@import 'animations';

$primary-color: #2f5d62;
$secondary-color: #5e8b7e;
$color-accent: #a7c4bc;
$color-light: #dfeeea;
$gl-border-radius: 5px;
$min-main-width: 50vw;
$max-main-width: 50rem;

body,
input,
button {
  font-family: 'Poppins';
}
a {
  text-decoration: none;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  &:disabled {
    background: #eee;
    box-shadow: none;
  }
}
.biz-loader {
  animation: rotate 0.5s infinite linear;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid transparent;
  border-right-color: $primary-color;
  border-radius: 50%;
}
.biz-login {
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  * {
    box-sizing: border-box;
  }
  > div {
    height: 100%;
    background: url('https://img.freepik.com/free-vector/random-square-halftone-pattern_1409-1062.jpg?size=626&ext=jpg');
  }
  .login-section {
    display: flex;
    align-items: center;
    justify-content: center;
    .login-card {
      .l-title {
        color: $primary-color;
        font-weight: bold;
        text-align: center;
        font-size: 2rem;
        margin-bottom: 5rem;
        text-shadow: 0 5px 5px #0002;

        > span {
          display: block;
          font-size: 1rem;
          font-weight: 500;
          color: $secondary-color;
          text-shadow: none;
        }
      }
      form {
        min-width: 20rem;
        margin: 1rem 0;
        .l-input-holder {
          display: flex;
          align-items: center;
          position: relative;
          margin-top: 1rem;
          > label {
            display: none;
            position: absolute;
            top: -1.3rem;
            font-size: 0.8rem;
          }
          .inp-icon {
            pointer-events: none;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            color: #0006;
            &--prefix {
              left: 1rem;
            }
            &--suffix {
              pointer-events: all;
              right: 1rem;
            }
          }
          input {
            width: 100%;
            padding: 1rem;
            padding-left: 3rem;
            font-size: 0.9rem;
            border: 1px solid #0004;
            border-radius: $gl-border-radius;
            outline: none;
            &:focus {
              border-color: $primary-color;
              ~ span,
              ~ label {
                color: $primary-color;
              }
            }
          }
        }
        .biz-login-btn {
          min-height: 3.5rem;
          margin-top: 1rem;
          padding: 0 0.5rem;
          width: 100%;
          border-radius: $gl-border-radius;
          color: #fff;
          font-size: 1rem;
          font-weight: 500;
          transition: 0.2s;
          background: linear-gradient(
            to bottom right,
            $secondary-color,
            $primary-color
          );
          &:hover:not(:disabled) {
            box-shadow: 0 1rem 1rem #0002;
          }
          &:active {
            box-shadow: none;
          }
          &:disabled {
            background: $color-accent;
          }
        }
      }
      a {
        display: inline-block;
        text-decoration: none;
        color: $primary-color;
        font-weight: 500;
      }
      .f-pass,
      .new-acc {
        text-align: center;
        font-size: 0.85rem;
        display: block;
        text-align: center;
      }
      .new-acc {
        margin-top: 5rem;
      }
    }
  }
}

.biz-dashboard-wrapper {
  background-color: #fff;
  * {
    box-sizing: border-box;
  }
}
.biz-dashboard {
  animation: dashPop .5s;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  .b-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 500;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: $secondary-color;
  }
  .biz-float-btn {
    // position: fixed;
    // right: 2rem;
    // transform: translateX(-50%);
    // bottom: 2rem;
    margin-top: 1rem;
    width: 100%;
    > button {
      padding: 1rem 1.5rem;
      font-size: 0.9rem;
      font-weight: bold;
      color: #fff;
      border-radius: $gl-border-radius;
      background: linear-gradient(
        to bottom right,
        $secondary-color,
        $primary-color
      );
      svg {
        font-size: 1.3rem;
        margin-right: 5px;
      }
    }
  }
  .left-panel {
    position: sticky;
    top: 1rem;
    span {
      display: block;
    }
    .biz-greet {
      display: flex;
      align-items: flex-start;
      margin: 1rem 0 1.5rem 0;
      .b-avatar {
        background-color: $primary-color;
      }
      > p {
        font-size: 0.8rem;
        margin: 0.3rem 0 0 0.4rem;
        span {
          font-size: 1rem;
          font-weight: bold;
        }
      }
    }
    .biz-time {
      margin-top: 1rem;
      padding: 0.7rem;
      border: 1px solid #0002;
      border-radius: $gl-border-radius;
      box-shadow: 0 2px 5px #0001;
      min-width: 12rem;
      background-color: #fff;
      > span {
        font-size: 0.8rem;
        font-weight: 500;
        padding: 0.5rem 0;
        &:first-child {
          padding-top: 0;
        }
        &:not(:first-child) {
          border-top: 1px solid #0001;
        }
        svg {
          font-size: 0.85rem;
          margin-right: 0.5rem;
          color: $primary-color;
        }
        > span:first-child {
          color: $secondary-color;
          font-size: 0.7rem;
          margin-bottom: 5px;
        }
        .date-time-span {
          display: inline-flex;
          align-items: center;
          font-weight: 500;
          > span {
            display: inline-block;
            color: #424242;
            &:nth-child(2) {
              margin-right: 0.5rem;
              color: $primary-color;
              //   font-weight: 500;
            }
          }
        }
      }
    }
  }
  .main-panel {
    width: $min-main-width;
    max-width: $max-main-width;
    margin: 0 3rem;
    .biz-header {
      position: sticky;
      top: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $primary-color;
      color: #fff;
      padding: 0.5rem;
      border: 1px solid #fff9;
      box-shadow: 0 5px 1rem #0002;
      z-index: 50;
      border-radius: $gl-border-radius * 10;
      > div {
        display: inline-flex;
        align-items: center;
      }
      p {
        margin: 0;
        font-size: 0.85rem;
      }
      .action-list {
        display: flex;
        margin: 0 0.5rem;
        li {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          font-size: 1.1rem;
          margin: 0 5px;
          transition: 0.2s;
          &:hover {
            color: $primary-color;
            background-color: $color-light;
          }
        }
      }
      .meet-sch-info {
        display: flex;
        align-items: center;
        color: #fffc;
        svg {
          box-sizing: content-box;
          padding: 0.5rem;
          background-color: #fff;
          margin-right: 0.8rem;
          color: $primary-color;
          border-radius: 50%;
          overflow: visible;
        }
        span {
          display: inline-block;
          margin: 0 5px;
          color: #fff;
          font-weight: 600;
        }
      }
    }
  }
  .right-panel {
    margin-top: 1rem;
    width: 18rem;
    // background-color: $color-light;
    // padding: 1rem;
    // height: 30rem;
    ul {
      li {
        position: relative;
        padding: 1rem;
        border: 1px solid #0002;
        overflow: hidden;
        margin-bottom: 0.5rem;
        background-color: #fff;
        color: #424242;
        // box-shadow: 0 2px 5px #0001;
      }
    }
    .biz-ad-list {
      li {
        height: 7rem;
        > span {
          font-size: 0.8rem;
          height: 1rem;
        }
      }
    }
    .biz-sponsor-list {
      li {
        font-style: italic;
        font-size: 0.8rem;
        height: 4rem;
      }
    }
  }
  .biz-heading {
    position: relative;
    display: flex;
    align-items: flex-start;
    margin: 3rem 0 2rem 0;
    font-weight: 500;
    font-size: 0.85rem;
    color: #424242;
    font-weight: 400;
    > p {
      display: flex;
      align-items: flex-start;
      margin: 0;
      z-index: 2;
      background-color: #fff;
      padding-right: 1rem;

      color: $primary-color;
    }
    svg {
      margin: 0.2rem 0.5rem 0 0;
    }
    span {
      display: none;
      // display: block;
      font-size: 0.7rem;
      color: #999;
    }
    &::after {
      content: '';
      position: absolute;
      border-top: 1px solid #ddd;
      left: 0;
      right: 0;
      top: 10px;
    }
  }
  .biz-suggestions {
    width: 100%;
    position: relative;
    border: 1px solid #0001;
    z-index: 5;
    margin-top: 2rem;
    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      top: 0;
      width: 1rem;
      background: linear-gradient(to left, #0001, transparent);
    }
    .nav-arrows {
      pointer-events: none;
      position: absolute;
      left: 1rem;
      top: 0;
      bottom: 0;
      right: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      z-index: 5;
      button {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        background-color: #fff;
        border: 1px solid #0001;
        pointer-events: all;
        box-shadow: 0 0.5rem 1.5rem #0001;
        font-size: 1rem;
        &:active {
          box-shadow: none;
        }
      }
    }
    > ul {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      overflow-x: auto;
      padding: 1rem 5rem;
      box-sizing: border-box;
      background-color: #fafafa;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .suggestion-card {
      position: relative;
      border: 1px solid #0002;
      padding: 1rem;
      min-width: 15rem;
      font-size: 0.8rem;
      margin-right: 1rem;
      border-radius: $gl-border-radius;
      transition: 0.2s;
      background-color: #fff;
      padding-bottom: 1.7rem;
      &:hover {
        box-shadow: 0 0.4rem 1.5rem #0001;
      }
      > button {
        width: 2.5rem;
        height: 2.5rem;
        position: absolute;
        right: 0.4rem;
        bottom: 0.4rem;
        border-radius: 50%;
        font-size: 1.2rem;
        border: 1px solid currentColor;
        background-color: #fff;
        color: $secondary-color;
        transition: 0.2s;
        // box-shadow: 0 2px .5rem $secondary-color;
        &:hover {
          background-color: $secondary-color;
          color: #fff;
        }
      }
      &--head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        > span {
          font-size: 0.7rem;
          &:first-child {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 50%;
            background-color: $color-light;
            color: $primary-color;
            text-transform: capitalize;
            font-weight: bold;
            font-size: 1rem;
            // box-shadow: 0 2px 5px #0005;
          }
        }
        ul {
          display: flex;
          > li {
            cursor: pointer;
            font-size: 0.9rem;
            margin-left: 0.2rem;
            &,
            a {
              color: #424242;
            }
            svg {
              box-sizing: content-box;
              padding: 5px;
              overflow: visible;
              border-radius: 50%;
              border: 1px solid transparent;
              transition: 0.2s;
            }
            &:hover {
              svg {
                color: $primary-color;
                border-color: $color-accent;
              }
            }
          }
        }
      }
      &--body {
        font-size: 0.7rem;
        > span {
          display: inline-flex;
          align-items: center;
          font-weight: 500;
          color: $secondary-color;
          svg {
            margin-right: 3px;
          }
        }
        // > p {
        //   font-size: 0.9rem;
        //   margin: 1rem 0 0 0;
        //   font-weight: 500;
        //   > span {
        //     display: inline-block;
        //     margin-left: 5px;
        //     font-size: 0.7rem;
        //     font-weight: normal;
        //     &::before {
        //       content: 'o';
        //       font-size: 5px;
        //     }
        //   }
        // }
      }
      //   &--bottom {
      //   }
    }
  }
  .biz-meetings {
    position: relative;
    margin-top: 2rem;
    min-height: 100vh;
    background-color: #fff;
    z-index: 20;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 1.5rem;
      border-left: 3px dashed $color-accent;
      z-index: -1;
    }
    .meet-wrapper {
      margin-bottom: 3rem;
      .head-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.5rem;
        height: 2.5rem;
        margin-right: 1rem;
        background-color: $primary-color;
        border-radius: 50%;
        color: #fff;
        z-index: 10;
        box-shadow: 0 5px 0.5rem #0003;
        font-size: 1.2rem;
      }
      .meet-date {
        position: sticky;
        top: 2rem;
        display: flex;
        align-items: center;
        z-index: 10;
        font-weight: 500;
        margin: 0 0 2rem 0.3rem;
        > div {
          padding: 0.3rem 0.8rem;
          box-shadow: 0 2px 5px #0001;
          border: 1px solid #0002;
          background-color: #fff;
          // background-color: $secondary-color;
          // color: #fff;
        }
      }
      .meet-card-wrapper {
        display: grid;
        grid-template-columns: auto 1fr;
        margin-top: 1em;
        .head-icon {
          background-color: $color-light;
          margin-left: 0.3rem;
          box-shadow: none;
          color: $primary-color;
          font-weight: bold;
          font-size: 1rem;
          z-index: 5;
          margin-top: 0.5rem;
        }
        .meet-card {
          width: 100%;
          border: 1px solid #0002;
          border-radius: $gl-border-radius;
          padding: 1rem;
          background-color: #fff;
          box-shadow: 0 2px 3px #0001;
          // border-bottom: 5px solid $color-accent;
          p {
            margin: 0;
          }
          &--head {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            > div {
              display: flex;
              flex-direction: column;
            }
            .m-h-about {
              margin: 0;
              font-size: 0.75rem;
              &--title {
                font-size: 1.2rem;
                font-weight: 500;
              }
              &--type {
                font-size: 0.9rem;
                color: $secondary-color;
              }
              &--more-info {
                display: flex;
                align-items: center;
                font-size: 0.8rem;
                font-weight: 500;
                color: $secondary-color;
                svg {
                  font-size: 0.7rem;
                }
                > span {
                  &:not(:first-child) {
                    margin: 0 0.5rem;
                  }
                }
              }
              &--memo {
                display: flex;
                align-items: flex-start;
                margin-top: 0.5rem;
                color: #424242;
                svg {
                  margin: 3px 0.3rem 0 0;
                }
              }
            }
            .m-h-timings {
              align-items: flex-end;
              font-size: 0.8rem;
              text-align: end;
              span {
                color: $secondary-color;
                &:first-child {
                  font-weight: 500;
                  color: $primary-color;
                  border-radius: $gl-border-radius;
                }
              }
            }
          }
          &--bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 1rem;
            .meet-action {
              &--active {
                animation: primaryRipple 2s infinite forwards;
                background-color: $primary-color;
                color: #fff;
              }
              &--no-border {
                border: none;
              }
            }
            ul {
              display: flex;
              align-items: center;
              > li {
                position: relative;
                margin-left: 1rem;
                > span {
                  display: inline-block;
                  position: absolute;
                  left: 50%;
                  bottom: calc(100% + 0.5rem);
                  transform: translateX(-50%);
                  font-size: 0.7rem;
                  background-color: #fff;
                  white-space: nowrap;
                  color: #424242;
                  padding: 3px 5px;
                  border-radius: $gl-border-radius;
                  pointer-events: none;
                  opacity: 0;
                  transition: 0.2s;
                  box-shadow: 0 3px 0.5rem #0002;
                }
                button {
                  width: 2.5rem;
                  height: 2.5rem;
                  border-radius: 50%;
                  font-size: 1rem;
                  background-color: #fff;
                  color: $primary-color;
                  border: 1px solid $primary-color;
                  transition: 0.2s;
                  &:hover {
                    background-color: $primary-color;
                    color: #fff;
                    box-shadow: 0 2px 1rem #0003;
                  }
                  &:active {
                    transform: scale(0.9);
                  }
                }
                &:hover {
                  > span {
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .biz-board {
    background-color: #fff;
    border-radius: $gl-border-radius;
    border: 1px solid #0002;
    font-size: 0.8rem;
    margin-bottom: 1rem;
    box-shadow: 0 2px 5px #0001;
    overflow: hidden;
    padding: 0.8rem;
    &--head {
      display: flex;
      align-items: center;
      color: $primary-color;
      font-weight: bold;
      margin-bottom: 0.3rem;
      svg {
        font-size: 0.9rem;
        margin-right: 0.5rem;
      }
    }
    &--events {
      > div {
        padding: 0.4rem 0;
        font-size: 0.75rem;
        &:not(:last-child) {
          border-bottom: 1px solid $color-light;
        }
        &:last-child {
          padding-bottom: 0;
        }
        > span {
          display: block;
          font-size: 0.65rem;
          color: $secondary-color;
        }
      }
    }
    &--announcements {
      > div {
        display: grid;
        grid-template-columns: auto 1fr;
        column-gap: 0.5rem;
        padding: 0.3rem 0;
        font-size: 0.75rem;
        &:not(:last-child) {
          border-bottom: 1px solid $color-light;
        }
        &:last-child {
          padding-bottom: 0;
        }
        svg {
          color: $secondary-color;
          margin-top: 3px;
        }
        a {
          color: #000;
        }
        &:hover {
          a,
          svg {
            color: $primary-color;
          }
        }
      }
    }
  }
}
